'use strict';

// funzione che accende e spegne i moduli Backbone
var startSubApp = function (appName, options) {
  options = options || {};

  /*
   Sometimes page scroll is blocked to avoid page flickering, here we make sure to re-enable it
   at each page change
   */
  $('body').css('overflow', '');

  if (Wethod.userInfo) {
    // stoppo l'app corrente prima di accendere quella nuova
    if (!_.isUndefined(Wethod[Wethod.currentApp])) {
      Wethod[Wethod.currentApp].stop();

      // faccio l'unbind dello scroll della pagina in modo da spegnere gli eventi
      // sullo scroll ascoltati dall'app appena chiusa
      dispatcher.off('html:page:scroll');

      // chiudo socket in modo che esca da eventuali room
      socket.emit('leave-rooms');
    }

    Wethod.HeaderApp.start();

    // show loading
    var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
    var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
    Wethod.regions.body.show(loadingView);
    // setto l'app passata come parametro come app corrente
    Wethod.currentApp = appName;

    Wethod[appName].start(options);
  }
};

/* Needed to avoid Backbone.Router to call decodeURIComponent on queryString: if it's decoded
 before calling HTTPService.getQueryParams(), '&' used as values are treated as '&' used to divide
 params and this makes impossible to extract query params.
 TODO: find a cleaner way to manage query params
 */
_.extend(Backbone.Router.prototype, {
  _extractParameters: function (route, fragment) {
    var params = route.exec(fragment).slice(1);
    return _.map(params, function (param) {
      return param || null;
    });
  },
});

Wethod.Router = Marionette.AppRouter.extend({

  // WARNING: do not change routes order!
  appRoutes: {
    '': 'goToIndex',

    // DASHBOARD
    dashboard: 'showDashboard',

    // DESK
    desk: 'showDesk',

    // PLANNING
    'planning/allocation-requests(?*filter)': 'showAllocationRequests',
    'planning/production-plan(/)(?:filter)': 'showProductionPlan',
    'planning/people?product_tour_id=:idTour': 'showPlanningPeopleTour',
    'planning/people(?*filter)': 'showPlanningPeople',
    'planning/projects(/)': 'showPlanningProjects',
    // fallback for old planning link
    'planning(/)': 'showPlanningPeople',
    'planning/:id': 'showPlanningPeople',

    // PIPELINE
    'pipeline/projects(?*filters)': 'showPipelineProject',
    'pipeline/opportunities(?*filters)': 'showPipelineOpportunities',
    'pipeline/project(/)': 'redirectToNewPipeline',
    'pipeline/project/:search': 'redirectToNewPipeline',
    'pipeline/project/:search/:type': 'redirectToNewPipeline',
    'pipeline/project/:search/:type/invoice-plan': 'showPipelineProjectInvoicePlan',
    'pipeline/program': 'showPipelineProgram',
    'pipeline/basket(/:search)': 'showPipelineBasket',
    'pipeline/basket(/)': 'showPipelineBasket',
    'pipeline/intercompany/:token': 'linkIntercompanyProject',

    // FRIDAY
    'timesheet(/date=:date)(?product_tour_id=:idTour)': 'showTimesheetDate',
    'timesheet(/:id)(/date=:date)(?product_tour_id=:idTour)': 'showTimesheet',
    'project-status(/:id)(?product_tour_id=:idTour)': 'showProjectStatus',

    // PROJECT INVOICE PLAN
    'project/:idProject/invoice-plan(/)': 'showProjectInvoicePlan',

    // PROJECT CANVAS
    'project/:idProject/canvas(?board=:idBoard)': 'showProjectCanvas',

    // PROJECT REVIEW
    'project/:idProject/review/:idReview': 'showProjectReview',

    // REPORT
    'reports/budget-consumption(?product_tour_id=:idTour)': 'showBubblesReport',
    'insights/revenues': 'showRevenuesInsights',
    'insights/gross-margin': 'showGrossMarginInsights',
    'reports/revenue-progress': 'showBubblesInvoiceReport',
    'reports/revenue-pipeline': 'showPipelineForecastReport',
    'project/:idProject/report(?product_tour_id=:idTour)': 'showProjectReport',
    'reports/timesheet': 'showPeople',
    'reports/timesheets-list': 'showTimesheetsList',
    'reports/timesheet-roadrunner': 'showTimesheetRoadrunner',
    'reports/production-value': 'showProductionValue',
    'reports/changelog': 'showProjectChangelog',
    'reports/budget-changelog': 'showBudgetChangelog',
    'reports/timesheet-changelog': 'showTimesheetChangelog',
    'reports/sales-overview': 'showSalesOverview',
    'reports/backlog': 'showBacklog',
    'reports/project-review-changelog': 'showReviewChangelog',
    'reports/briefing/weekly': 'showWeeklyBriefing',
    'reports/briefing/monthly': 'showMonthlyBriefing',
    'reports/demographics': 'showDemographics',
    'reports/chargeability': 'showChargeability',
    'reports/kudos': 'showKudos',
    'reports/knowledge': 'showKnowledge',

    // TIME OFF MANAGER
    'time-off-manager(/)': 'showTimeOffManager',

    // FINANCE
    'finance/invoice(/:search)': 'showInvoice',
    'finance/invoice(/)': 'showInvoice',
    'finance/order(/:search)': 'showOrder',
    'finance/order(/)': 'showOrder',
    'finance/client/person/:id': 'showPersonClient',
    'finance/client/company/:id': 'showCompanyClient',
    'finance/client/:search': 'showClient',
    'finance/suppliers/:id': 'showSupplierDetail',
    'finance/suppliers': 'showSuppliers',

    // TRAVEL
    'travel(/:id)': 'showTravel',
    'travel(/)': 'showTravel',

    // EXPENSES
    'expenses(/:id)': 'showExpenses',
    'expenses(/)': 'showExpenses',

    // TIMETABLE
    'timeline/:id': 'showTimeline',
    timeline: 'showCompanyTimeline',

    // BUDGET
    'pipeline/budget/:id(?product_tour_id=:idTour)': 'showBudgetTour',
    'pipeline/budget/:id/version/:version': 'showBudget',
    'pipeline/budget/:id/new': 'showBudget',
    'pipeline/budget/:id/correction': 'correctBudget',

    // BUDGET TEMPLATES
    'budget-template': 'showBudgetTemplate',

    // BUDGET PRODUCTS
    products: 'showProducts',

    // BUDGET RATES
    pricelists: 'showPricelists',

    // PROGRAM CHARTER
    'program(/)': 'showPipelineProgram', // same as 'pipeline/program'
    'program/new': 'createAddProjectCharter',
    'program/new/:id': 'createAddProjectCharter',
    'program/:id': 'showProjectCharter',
    // Old routing for PROGRAM CHARTER keep this for compatibility
    'project-charter': 'createAddProjectCharter',
    'project-charter/new': 'createAddProjectCharter',
    'project-charter/new/:id': 'createAddProjectCharter',
    'project-charter/:id': 'showProjectCharter',

    // SETTINGS
    'settings/account': 'showAccount',
    'settings/team(?product_tour_id=:idTour)': 'showTeam',
    'settings/company(/:idSection)(/:action)(?product_tour_id=:idTour)': 'showCompany',
    'settings/rules': 'showRules',
    'settings/alerts': 'showAlertsEngine',
    'settings/profile/:id': 'showProfile',
    'settings/permissions(?product_tour_id=:idTour)': 'showPermissions',

    // FINANCIALS
    'data-freezing/engine': 'showDataFreezingEngine',

    // Logout
    logout: 'logout',

    // Everything else
    '*notFound': 'goTo404',
  },
  onRoute: function () {
    googleAnalytics.trackPageView();
  },
});

var API = {
  showPipelineProject: function (filters) {
    var options = {
      foo: 'showPipelineProject',
      filters: filters || '',
      type: 'projects',
    };
    startSubApp('PipelineApp', options);
  },

  showPipelineOpportunities: function (filters) {
    var options = {
      foo: 'showPipelineProject',
      filters: filters || '',
      type: 'opportunities',
    };
    startSubApp('PipelineApp', options);
  },

  redirectToNewPipeline: function () {
    var options = {
      foo: 'redirectToNewPipeline',
    };
    startSubApp('PipelineApp', options);
  },

  showProjectInvoicePlan: function (idProject) {
    var options = {
      foo: 'showProjectInvoicePlan',
      idProject: idProject,
    };
    startSubApp('InvoicePlanApp', options);
  },

  showPipelineProjectInvoicePlan: function (search, type) {
    type = type ? decodeURIComponent(type) : null;
    search = search ? decodeURIComponent(search) : '';
    var projectId = Number(search);
    search = Number(search) ? 'id:' + search : search;
    var options = {
      foo: 'showPipelineProjectInvoicePlan',
      projectId: projectId,
      type: type,
      search: search,
    };
    startSubApp('PipelineApp', options);
  },

  showPipelineProgram: function () {
    var options = { foo: 'showPipelineProgram' };
    startSubApp('PipelineApp', options);
  },

  showPipelineBasket: function (params) {
    var options = {
      foo: 'showPipelineBasket',
      params: params,
    };
    startSubApp('PipelineApp', options);
  },

  linkIntercompanyProject: function (token) {
    var options = {
      foo: 'linkIntercompanyProject',
      params: token,
    };
    startSubApp('PipelineApp', options);
  },

  showProjectChangelog: function () {
    var options = { foo: 'showProjectChangelog' };
    startSubApp('ReportApp', options);
  },

  showBudgetChangelog: function () {
    var options = { foo: 'showBudgetChangelog' };
    startSubApp('ReportApp', options);
  },

  showSalesOverview: function () {
    var options = { foo: 'showSalesOverview' };
    startSubApp('ReportApp', options);
  },

  showBacklog: function () {
    var options = { foo: 'showBacklog' };
    startSubApp('ReportApp', options);
  },

  showReviewChangelog: function () {
    var options = { foo: 'showReviewChangelog' };
    startSubApp('ReportApp', options);
  },

  showWeeklyBriefing: function () {
    var options = { foo: 'showWeeklyBriefing' };
    startSubApp('BriefingApp', options);
  },

  showMonthlyBriefing: function () {
    var options = { foo: 'showMonthlyBriefing' };
    startSubApp('BriefingApp', options);
  },

  showDemographics: function () {
    var options = { foo: 'showDemographics' };
    startSubApp('ReportApp', options);
  },

  showChargeability: function () {
    var options = { foo: 'showChargeability' };
    startSubApp('ReportApp', options);
  },

  showKudos: function () {
    var options = { foo: 'showKudos' };
    startSubApp('ReportApp', options);
  },

  showKnowledge: function () {
    var options = { foo: 'showKnowledge' };
    startSubApp('ReportApp', options);
  },

  showPlanningPeople: function (query) {
    var options = {
      query: query,
      foo: 'showPlanningPeople',
    };
    startSubApp('PlanningApp', options);
  },

  showPlanningPeopleTour: function (idTour) {
    var options = {
      foo: 'showPlanningPeople',
      idTour: idTour,
    };
    startSubApp('PlanningApp', options);
  },

  showPlanningProjects: function () {
    var options = { foo: 'showPlanningProjects' };
    startSubApp('PlanningApp', options);
  },

  showProductionPlan: function (filter) {
    startSubApp('PlanningApp', {
      filter: filter,
      foo: 'showProductionPlan',
    });
  },

  showAllocationRequests: function (filter) {
    startSubApp('PlanningApp', {
      filter: filter,
      foo: 'showAllocationRequests',
    });
  },

  showTimesheetDate: function (date, idTour) {
    this.showTimesheet(null, date, idTour);
  },

  showTimesheet: function (id, date, idTour) {
    var idUser = id ? parseInt(id) : null;
    var options = {
      foo: 'showTimesheet',
      id: idUser,
      date: date,
      idTour: idTour,
    };
    startSubApp('FridayApp', options);
  },

  showProjectStatus: function (id, idTour) {
    var options = {
      foo: 'showProjectStatus',
      id: id,
      idTour: idTour,
    };
    startSubApp('FridayApp', options);
  },

  showBubblesReport: function (idTour) {
    var options = {
      foo: 'showBubblesReport',
      idTour: idTour,
    };
    startSubApp('ReportApp', options);
  },

  showRevenuesInsights: function () {
    var options = {
      foo: 'showRevenuesInsights',
    };
    startSubApp('InsightsApp', options);
  },
  showGrossMarginInsights: function () {
    var options = {
      foo: 'showGrossMarginInsights',
    };
    startSubApp('InsightsApp', options);
  },

  showBubblesInvoiceReport: function () {
    var options = { foo: 'showBubblesInvoiceReport' };
    startSubApp('ReportApp', options);
  },

  showPipelineForecastReport: function () {
    var options = { foo: 'showPipelineForecastReport' };
    startSubApp('ReportApp', options);
  },

  showProjectReport: function (idProject, idTour) {
    var options = {
      foo: 'showProjectReport',
      idProject: idProject,
      idTour: idTour,
    };
    startSubApp('ReportApp', options);
  },

  showProjectCanvas: function (idProject, idBoard) {
    var options = {
      foo: 'showProjectCanvas',
      idProject: idProject,
      idBoard: idBoard,
    };
    startSubApp('ProjectCanvasApp', options);
  },

  showProjectReview: function (idProject, idReview) {
    var options = {
      foo: 'showProjectReview',
      idProject: idProject,
      idReview: idReview,
    };
    startSubApp('ProjectReviewApp', options);
  },

  showPeople: function () {
    var options = { foo: 'showPeople' };
    startSubApp('ReportApp', options);
  },

  showTimesheetRoadrunner: function () {
    var options = { foo: 'showTimesheetRoadrunner' };
    startSubApp('ReportApp', options);
  },

  showTimesheetsList: function () {
    var options = { foo: 'showTimesheetsList' };
    startSubApp('ReportApp', options);
  },

  showTimesheetChangelog: function () {
    var options = { foo: 'showTimesheetChangelog' };
    startSubApp('ReportApp', options);
  },

  showProductionValue: function () {
    var options = { foo: 'showProductionValue' };
    startSubApp('ReportApp', options);
  },

  showTimeOffManager: function () {
    var options = { foo: 'showTimeOffManager' };
    startSubApp('TimeOffApp', options);
  },

  showInvoice: function (params) {
    var options = {
      foo: 'showInvoice',
      params: params,
    };
    startSubApp('FinanceApp', options);
  },

  showOrder: function (params) {
    var options = {
      foo: 'showOrder',
      params: params,
    };
    startSubApp('FinanceApp', options);
  },
  showClient: function (search) {
    startSubApp('FinanceApp', {
      foo: 'showClient',
      params: { search: search },
    });
  },

  showPersonClient: function (params) {
    startSubApp('FinanceApp', {
      foo: 'showPersonClient',
      params: params,
    });
  },

  showCompanyClient: function (params) {
    startSubApp('FinanceApp', {
      foo: 'showCompanyClient',
      params: params,
    });
  },

  showSuppliers: function () {
    startSubApp('FinanceApp', { foo: 'showSuppliers' });
  },

  showSupplierDetail: function (params) {
    startSubApp('FinanceApp', {
      foo: 'showSupplierDetail',
      params: params,
    });
  },

  showTravel: function (id) {
    var options = {
      foo: 'showTravel',
      id: id,
    };
    startSubApp('TravelApp', options);
  },

  showExpenses: function (id) {
    var options = {
      foo: 'showExpenses',
      id: id,
    };
    startSubApp('ExpensesApp', options);
  },

  showTimeline: function (id) {
    startSubApp('TimelineApp', {
      foo: 'showTimeline',
      id: id,
    });
  },

  showCompanyTimeline: function () {
    startSubApp('TimelineApp', { foo: 'showCompanyTimeline' });
  },

  showBudget: function (id, version) {
    var options = {
      foo: 'showBudget',
      id: id,
      version: version || null,
      withCorrection: false,

    };
    startSubApp('BudgetApp', options);
  },

  showBudgetTour: function (id, idTour) {
    var options = {
      foo: 'showBudget',
      id: id,
      version: null,
      withCorrection: false,

      idTour: idTour,
    };
    startSubApp('BudgetApp', options);
  },

  correctBudget: function (id) {
    var options = {
      foo: 'showBudget',
      id: id,
      version: null,
      withCorrection: true,

    };
    startSubApp('BudgetApp', options);
  },

  showBudgetTemplate: function () {
    startSubApp('BudgetTemplateApp', { foo: 'showBudgetTemplate' });
  },

  showProjectCharter: function (id) {
    var options = {
      foo: 'showProjectCharter',
      id: id,
    };
    startSubApp('ProjectCharterApp', options);
  },

  createAddProjectCharter: function (id) {
    var options = {
      foo: 'createAddProjectCharter',
      id: id,
    };
    startSubApp('ProjectCharterApp', options);
  },

  showAccount: function () {
    var options = { foo: 'showAccount' };
    startSubApp('SettingsApp', options);
  },

  showRules: function () {
    var options = { foo: 'showRules' };
    startSubApp('SettingsApp', options);
  },

  showAlertsEngine: function () {
    var options = { foo: 'showAlertsEngine' };
    startSubApp('SettingsApp', options);
  },

  showProfile: function (id) {
    var options = {
      foo: 'showProfile',
      id: id,
    };
    startSubApp('SettingsApp', options);
  },

  showTeam: function (idTour) {
    var options = {
      foo: 'showTeam',
      idTour: idTour,
    };
    startSubApp('SettingsApp', options);
  },

  showCompany: function (idSection, action, idTour) {
    var options = {
      foo: 'showCompany',
      idSection: idSection,
      action: action,
      idTour: idTour,
    };
    startSubApp('SettingsApp', options);
  },

  showPermissions: function (idTour) {
    var options = {
      foo: 'showPermissions',
      idTour: idTour,
    };
    startSubApp('SettingsApp', options);
  },

  showPayment: function () {
    var options = { foo: 'showAccount' };
    Wethod.navigate('settings/account');
    startSubApp('SettingsApp', options);
  },

  showDashboard: function () {
    startSubApp('DashboardApp', {});
  },

  showDesk: function () {
    startSubApp('DeskApp', { foo: 'showDesk' });
  },

  showProducts: function () {
    startSubApp('ProductApp', { foo: 'showProducts' });
  },

  showPricelists: function () {
    startSubApp('PricelistApp', { foo: 'showPricelists' });
  },

  showDataFreezingEngine: function () {
    var options = { foo: 'showDataFreezingEngine' };
    startSubApp('DataFreezingApp', options);
  },

  goToIndex: function () {
    if (!Wethod.userInfo) {
      return;
    }

    if (Wethod.userInfo.get('code') === 402) {
      window.location.replace(URL + '/access/#block');
    } else if (Wethod.userInfo.get('code') === 451) {
      window.location.replace(URL + '/access/#policy');
    } else if (Wethod.userInfo.get('company_active')) {
      Wethod.navigate('desk');
      startSubApp('DeskApp', { foo: 'showDesk' });
    } else {
      Wethod.navigate('settings/account');
      var options = {
        foo: 'showAccount',
        showPayment: true,

      };
      startSubApp('SettingsApp', options);
    }
  },

  goTo404: function () {
    startSubApp('NotFoundApp');
  },

  logout: function () {
    // Log out the current user and redirect him to login page
    dispatcher.trigger('user:logout');
  },
};

// BEFORE START
Wethod.on('before:start', function () {
  // LAYOUTVIEW
  var Layout = Marionette.LayoutView.extend({
    el: 'body',
    regions: {
      body: '[data-region="appBody"]',
    },
  });
  Wethod.regions = new Layout();

  // ROUTER
  // eslint-disable-next-line no-new
  new Wethod.Router({
    controller: API,
  });
});
